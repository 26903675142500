import React, { Component } from "react";
import "./App.css";
import { SimpleMap } from "./component";
import { StateContainer } from "./component/state-container/state.container";
import { configureStore, history } from "./core";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

let store = configureStore({});

store.dispatch({ type: "START_UP" });

export class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <>
            <Route
              path="/"
              exact
              component={() => {
                return <SimpleMap />;
              }}
            />
            <Route
              path="/all"
              exact
              component={() => {
                return <SimpleMap />;
              }}
            />
          </>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
