import React, { Component, FunctionComponent } from "react";
// import GoogleMapReact, { GoogleMapMarkers, InfoWindow } from "google-map-react";
import {
  GoogleApiWrapper,
  Map,
  Marker,
  InfoWindow,
  MarkerProps
} from "google-maps-react";
import { IGymData } from "../../data/gyms.data";
import { gymDictionary, determineRaid } from "./map.marker.componet";
import { GymContext } from "../state-container/state.container";
import moment from "moment";
import expiredGym from "../../assets/expired-gym-marker.png";
import egg1 from "../../assets/egg1.png";
import egg2 from "../../assets/egg2.png";
import egg3 from "../../assets/egg3.png";
import egg4 from "../../assets/egg4.png";
import egg5 from "../../assets/egg5.png";
import blueGym from "../../assets/blue-gym-marker.png";
import redGym from "../../assets/red-gym-marker.png";
import yellowGym from "../../assets/yellow-gym-marker.png";
import rayquazaSprite from "../../assets/pokemon-sprites/384-sprite.png";
import dialgaSprite from "../../assets/pokemon-sprites/483-sprite.png";
import { spritesPokedex } from "../../data/sprites.pokedex";
import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
  GymContainerProps
} from "./map.container";

const isGymStale = (gym: IGymData) => {
  return (
    !gym.date_updated ||
    moment(gym.date_updated)
      .add("1", "hour")
      .isBefore(moment())
  );
};

const generateDisplayString = (gymData: IGymData | undefined) => {
  if (!gymData) {
    return "";
  }
  if (!gymData.raidSpawnMs || isGymStale(gymData)) {
    return `Coordinates are ${gymData.latitude}, ${gymData.longitude}`;
  }

  const isDaylightSaveTime = moment().isDST();

  const startTime = moment(gymData.raidSpawnMs)
    .add("1", "hour")
    .format("h:mm a");

  const endTime = moment(gymData.raidEndMs).format("h:mm a");

  return (
    `Raid ${
      gymData.raidLevel
    } starts at ${startTime} and ends at ${endTime} with pokedex number ${
      gymData.raidPokemon
    }.` +
    "\n" +
    `Coordinates are ${gymData.latitude}, ${gymData.longitude}`
  );
};

export class MapComponent extends Component<GymContainerProps, any> {
  constructor(props: GymContainerProps) {
    super(props);

    this.state = {
      showingInfoWindow: false, //Hides or the shows the infoWindow
      activeMarker: {}, //Shows the active marker upon click
      selectedPlace: {}, //Shows the infoWindow to the selected place upon a marker
      selectedGym: ""
    };
  }

  bigFunction = (gym: IGymData, key: number) => {
    const position = {
      lat: gym.latitude,
      lng: gym.longitude
    };
    let markerUrl = "";

    switch (gym.team) {
      case 1:
        markerUrl = blueGym;
        break;
      case 2:
        markerUrl = redGym;
        break;
      case 3:
        markerUrl = yellowGym;
        break;
    }

    switch (gym.raidLevel) {
      case 1:
        markerUrl = "https://img.icons8.com/material/32/000000/1-c.png";
        break;
      case 2:
        markerUrl = "https://img.icons8.com/material/32/000000/2-c.png";
        break;
      case 3:
        markerUrl = "https://img.icons8.com/material/32/000000/3-c.png";
        break;
      case 4:
        markerUrl = "https://img.icons8.com/material/32/000000/4-c.png";
        break;
      case 5:
        markerUrl = "https://img.icons8.com/material/32/000000/5-c.png";

        break;
    }

    if (gym.raidLevel && spritesPokedex.get(gym.raidPokemon)) {
      markerUrl = spritesPokedex.get(gym.raidPokemon);
    }

    if (
      gym.raidLevel &&
      !gym.raidPokemon &&
      gym.raidSpawnMs < new Date().getTime() + 3600 * 1000
    ) {
      switch (gym.raidLevel) {
        case 1:
          markerUrl = egg1;
          break;
        case 2:
          markerUrl = egg2;
          break;
        case 3:
          markerUrl = egg3;
          break;
        case 4:
          markerUrl = egg4;
          break;
        case 5:
          markerUrl = egg5;
          break;
      }
    }

    if (
      !gym.date_updated ||
      moment(gym.date_updated)
        .add("30", "minutes")
        .isBefore(moment())
    ) {
      markerUrl = expiredGym;
    }
    return (
      <Marker
        onClick={this.onMarkerClick}
        key={key}
        position={position}
        {...gym}
        icon={{ url: markerUrl }}
      />
    );
  };

  onMarkerClick = (props?: any, marker?: any, event?: any): any => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      selectedGym: props.gym_id
    });
  };

  onMapClicked = (props: any) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  render() {
    const gyms = this.props.gymArray;
    const gymMap = this.props.gymDictionary;
    const selectedGymData = gymMap[this.state.selectedGym];
    const filteredGyms = gyms.filter((__, i) => i <= 255);
    const infoString = generateDisplayString(selectedGymData);

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "100vh", width: "100%" }}>
        <Map
          google={this.props.google}
          zoom={14}
          initialCenter={{
            lat: 40.1857,
            lng: -75.4516
          }}
          onClick={this.onMapClicked}
        >
          {filteredGyms.map(this.bigFunction)}

          <InfoWindow
            visible={this.state.showingInfoWindow}
            marker={this.state.activeMarker}
          >
            <div>
              <p>{selectedGymData && infoString}</p>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

const MapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapComponent);

export const SimpleMap = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GA_API_KEY || ""
})(MapContainer);
