import { actionCreator } from "../../action";
import { IGymData } from "./gym.models";

export enum GymActions {
  UPDATE = "gyms::UPDATE",
  MASS_UPDATE = "gyms::MASS_UPDATE"
}

export const updateGymActionCreator = actionCreator<IGymData>(
  GymActions.UPDATE
);

export const massUpdateGymActionCreator = actionCreator<IGymData[]>(
  GymActions.MASS_UPDATE
);
