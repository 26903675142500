import { createEpicMiddleware, Options } from "redux-observable";
import { EpicDependencies } from "./epic.dependencies.types";

export const createReduxEpicMiddleware = (dependencies: EpicDependencies) => {
  const middlewareOptions: Options<EpicDependencies> = {
    dependencies
  };

  const epicMiddleware = createEpicMiddleware(middlewareOptions);

  return epicMiddleware;
};
