import sprite483 from "../assets/pokemon-sprites/483-sprite.png";
import sprite384 from "../assets/pokemon-sprites/384-sprite.png";
import sprite26 from "../assets/pokemon-sprites/26-sprite.png";
import sprite62 from "../assets/pokemon-sprites/62-sprite.png";
import sprite65 from "../assets/pokemon-sprites/65-sprite.png";
import sprite68 from "../assets/pokemon-sprites/68-sprite.png";
import sprite103 from "../assets/pokemon-sprites/103-sprite.png";
import sprite105 from "../assets/pokemon-sprites/105-sprite.png";
import sprite106 from "../assets/pokemon-sprites/106-sprite.png";
import sprite107 from "../assets/pokemon-sprites/107-sprite.png";
import sprite112 from "../assets/pokemon-sprites/112-sprite.png";
import sprite129 from "../assets/pokemon-sprites/129-sprite.png";
import sprite147 from "../assets/pokemon-sprites/147-sprite.png";
import sprite232 from "../assets/pokemon-sprites/232-sprite.png";
import sprite248 from "../assets/pokemon-sprites/248-sprite.png";
import sprite256 from "../assets/pokemon-sprites/256-sprite.png";
import sprite286 from "../assets/pokemon-sprites/286-sprite.png";
import sprite303 from "../assets/pokemon-sprites/303-sprite.png";
import sprite307 from "../assets/pokemon-sprites/307-sprite.png";
import sprite359 from "../assets/pokemon-sprites/359-sprite.png";
import sprite391 from "../assets/pokemon-sprites/391-sprite.png";
import sprite403 from "../assets/pokemon-sprites/403-sprite.png";

export const spritesPokedex = new Map<number, any>([
  [483, sprite483],
  [384, sprite384],
  [26, sprite26],
  [62, sprite62],
  [65, sprite65],
  [68, sprite68],
  [103, sprite103],
  [105, sprite105],
  [106, sprite106],
  [107, sprite107],
  [112, sprite112],
  [129, sprite129],
  [147, sprite147],
  [232, sprite232],
  [248, sprite248],
  [256, sprite256],
  [286, sprite286],
  [303, sprite303],
  [307, sprite307],
  [359, sprite359],
  [391, sprite391],
  [403, sprite403]
]);
