import { switchMap, merge, mergeMap, map } from "rxjs/operators";
import { interval } from "rxjs/internal/observable/interval";
import { from } from "rxjs/internal/observable/from";
import { ActionsObservable } from "redux-observable";
import { Action, ActionCreator } from "../../action";
import { IGymData } from "./gym.models";
import { massUpdateGymActionCreator } from "./gyms.action-creators";

const flattenArray = <ARRAY_TYPE>(anArray: ARRAY_TYPE[]) => {
  return from(anArray);
};

const mapToActionCreator = <PAYLOAD>(actionCreator: ActionCreator<PAYLOAD>) => (
  payload: PAYLOAD
) => actionCreator(payload);

const getData = async () => {
  const gyms: IGymData[] = await fetch(
    "https://4yspva7jtk.execute-api.us-east-1.amazonaws.com/default/list-pokemongo-raids"
  ).then(res => res.json());

  return gyms;
};

export const gymEpic = (action$: ActionsObservable<Action<any>>) => {
  return action$.ofType("START_UP").pipe(
    switchMap(() => {
      return interval(8000);
    }),
    switchMap(getData),
    // mergeMap(flattenArray),
    map(mapToActionCreator(massUpdateGymActionCreator))
  );
};
