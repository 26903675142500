import { Action } from "../../action";
import { GymActions } from "./gyms.action-creators";
import { IGymData } from "../../../data/gyms.data";
import { GymTable } from "./gym.models";

export const gymReducer = (state: GymTable = {}, action: Action<any>) => {
  const oldState = state;

  switch (action.type) {
    case GymActions.UPDATE: {
      const typedAction: Action<IGymData> = action;
      const { gym_id } = typedAction.payload;
      const newState = { ...state };
      newState[gym_id] = typedAction.payload;

      return newState;
    }

    case GymActions.MASS_UPDATE: {
      const typedAction: Action<IGymData[]> = action;
      const gyms = typedAction.payload;
      const newState = gyms.reduce(
        (agg: GymTable, gymData: IGymData) => {
          const { gym_id } = gymData;
          agg[gym_id] = gymData;
          return agg;
        },
        { ...state }
      );

      return newState;
    }

    default: {
      return { ...oldState };
    }
  }
};
