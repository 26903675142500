import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { gymReducer } from "../../features";
import { IRootState } from "./root-state.types";

export const rootReducer = (history: History) =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    gyms: gymReducer,
    setup: () => {
      return new Date().getTime();
    }
  });
