import { applyMiddleware, createStore, compose } from "redux";
import { rootReducer, IRootState } from "./reducers";
import { EpicDependencies, createReduxEpicMiddleware, rootEpic } from "./epics";
import { createBrowserHistory } from "history";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

export const history = createBrowserHistory();

export function configureStore(
  dependencies: EpicDependencies,
  initialState?: IRootState
) {
  const epicMiddleware = createReduxEpicMiddleware(dependencies);

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer(history),
    composeEnhancers(applyMiddleware(epicMiddleware))
  );

  epicMiddleware.run(rootEpic);

  return store;
}
