import { GymTable } from "../../core/features";
import { IGymData } from "../../data/gyms.data";
import { IRootState } from "../../core/setup/reducers";
import { toPairs } from "lodash";
import moment from "moment";
// import { ActionCreatorType } from 'core/redux-store/action';
// import {
//   updateCalculationAttributeRowActionCreator,
//   resetNodeTableActionCreator
// } from 'core/redux-store/calculation-node-table';
// import {
//   CreateCalculationFormModel,
//   CreateCalculationFormState,
//   loadCalculationActionCreator,
//   resetFormActionCreator,
//   submitFormActionCreator,
//   setFormDirtyStateActionCreator,
//   openModalActionCreator
// } from 'core/redux-store/create-calculation-form/';
// import { RootStateInterface } from 'core/redux-store/store/';
// import { UUID } from 'core/utils/uuid';
// import { push } from 'react-router-redux';
// import { selectFirstNodeId } from 'ui/+pages/+create-calculation-page/calculation-node/node.selector';
// import { selectIsSaveEnabled } from 'ui/+pages/+create-calculation-page/create-calculation.selector';
// import { selectCalculationName } from './calculation-attributes-component';

interface IGymContainerViewProps {
  gymDictionary: GymTable;
  gymArray: IGymData[];
}

interface IGymContainerOwnProps {
  google: any;
}

interface IGymContainerDispatchProps {}

// interface CreateCalculationFormContainerOwnProps {
//   calculationId: string;
// }

// interface CreateCalculationFormContainerDispatchProps {
//   loadCalculationActionCreator: ActionCreatorType<any>;
//   updateCalculationAttributeRowActionCreator: ActionCreatorType<any>;
//   submitFormActionCreator: ActionCreatorType<any>;
//   resetFormActionCreator: ActionCreatorType<any>;
//   resetNodeTableActionCreator: ActionCreatorType<any>;
//   setFormDirtyStateActionCreator: ActionCreatorType<any>;
//   openModalActionCreator: ActionCreatorType<any>;
//   push: any;
// }

export type GymContainerProps = IGymContainerViewProps &
  IGymContainerOwnProps &
  IGymContainerDispatchProps;

// This will eventually become a template so having the blank interface is acceptable
interface CreateCalculationFormContainerState {}

const mapStateToProps = (
  rootState: IRootState,
  ownProps: any
): IGymContainerViewProps & IGymContainerOwnProps => {
  const gymDictionary = rootState.gyms;
  const isRootPath = rootState.router.location.pathname === "/";

  const gymArray = toPairs(gymDictionary)
    .map(([gymId, gymValue]) => gymValue)
    .filter(gym => {
      if (isRootPath) {
        return !!gym.raidLevel;
      } else {
        return true;
      }
    })
    .filter(gym => {
      return !moment(gym.date_updated).isBefore(moment().startOf("day"));
    });

  console.log();

  return {
    gymDictionary,
    gymArray,
    google: ownProps.google
  };
};

const mapDispatchToProps: IGymContainerDispatchProps = {};

// export {
//   CreateCalculationFormContainerProps,
//   CreateCalculationFormContainerState
// };

export { mapStateToProps, mapDispatchToProps };

// export const ConnectedCreateCalculationFormContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(CreateCalculationFormContainer);
