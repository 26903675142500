import { combineEpics, ActionsObservable } from "redux-observable";
import { map } from "rxjs/operators";
import { gymEpic } from "../../features";
// type epic = function (action$: Observable<Action>, state$: StateObservable<State>): Observable<Action>

const startUpEpic = (action$: ActionsObservable<{ type: string }>) => {
  return action$.ofType("START_UP").pipe(
    map(() => {
      return { type: "Start Up Done", payload: undefined };
    })
  );
};

export const rootEpic = combineEpics(startUpEpic, gymEpic);
