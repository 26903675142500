export type Action<PAYLOAD> = {
  type: string;
  payload: PAYLOAD;
};

export type ActionCreator<PAYLOAD> = (payload: PAYLOAD) => Action<PAYLOAD>;

export const actionCreator = <PAYLOAD>(type: string) => (payload: PAYLOAD) => {
  return {
    type,
    payload
  };
};
